$(document).ready(function () {
  //sélection date
  $("#annee").on("change", function () {
    if (this.value != "") {
      $("#mois").show();
    } else {
      $("#mois").val("").hide();
    }
  });

  //miniature / preview
  $(".contenu").each(function () {
    let defaultPreviewSrc = $(this)
      .find(".contenu__miniatures img:first-child")
      .attr("src");
    $(this).find(".contenu__preview img").attr("src", defaultPreviewSrc);
  });

  $(".contenu__miniatures img").on("click", function () {
    let cheminImage = $(this).attr("src");
    $(".contenu__miniatures img").removeClass("active");
    $(this).addClass("active");
    $(".contenu__preview img").attr("src", cheminImage);
  });

  // Modal - Zoom sur une image
  var modal = document.getElementById("modal");

  var img = document.getElementById("preview");
  var modalImg = document.getElementById("img01");
  img.onclick = function () {
    modal.style.display = "block";
    modalImg.src = this.src;
  };

  var span = document.getElementsByClassName("contenu__modal-close")[0];
  var contenu_modal = document.getElementsByClassName("contenu__modal")[0];

  span.onclick = function () {
    modal.style.display = "none";
  };
  contenu_modal.onclick = function () {
    modal.style.display = "none";
  };
});
